import React from 'react'
import './wrapper-background.css'
import styled from "styled-components"
import theme from "../theme"

const Wrapper = styled.div`
  max-width: 660px;
  margin: auto;

  @media (max-width: ${theme.mediaQuery.small}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Content = ( props ) => {
  
  return (

    <Wrapper className={props.className}>
      {props.children}
    </Wrapper>
  )
}

export default Content
import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './menu.styled';
import styled from "styled-components"
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

const Socials = styled.ul `
  li {
    display: inline-block;
    margin-top: 30px;
    padding: 0 15px;
  }
`

const Menu = ({ open }) => {
  return (

    <StyledMenu open={open}>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>          
          <li><Link to="buy-chiva-beans">Buy our beans</Link></li>
          <li><Link to="about-chiva">About Chiva</Link></li>
          <li><Link to="contact">Get in touch</Link></li>
          <li><Link to="privacy">Privacy</Link></li>
          <li><Link to="terms">Terms</Link></li>
        </ul>
        <Socials>
          <li><a href="https://www.instagram.com/drinkchiva/"><FontAwesomeIcon icon={faInstagram} /></a></li>
          <li><a href="https://twitter.com/drinkchiva"><FontAwesomeIcon icon={faTwitter} /></a></li> 
        </Socials>
      </nav>
    </StyledMenu>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}
export default Menu;
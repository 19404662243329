/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { createGlobalStyle } from "styled-components"
import Footer from "./footer"
import theme from "../theme"
import "../fonts/fonts.css";
import "@fortawesome/fontawesome-svg-core/styles.css"

const GlobalStyle = createGlobalStyle`
  body {
    color: ${theme.color.black};
    letter-spacing: 0.05rem;
  }
  strong {
    font-weight: 600;
  }
  a:link:not(.button), a:visited:not(.button) {
    color: ${theme.color.black};
  }
  button, .button {
    color: ${theme.color.white};
    background-color: ${theme.color.black};
    border: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 12px 25px;
    transition: ${theme.transition.all};
    text-decoration: none;
    display: inline-block;

    &:hover {
      color: ${theme.color.black};
      background-color: ${theme.color.gray};
    }
  }`

const Layout = ({ children }) => {


  return (
    <>
      <GlobalStyle />

      <div>
        <main>{children}</main>
      </div>

      <Footer />

    </>
  )
}



export default Layout

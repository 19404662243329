import { Link } from "gatsby"
import React from "react"
import ImageChivaLogoSmall from "../components/img-chiva-logo-small"
import BackgroundWrapper from '../components/wrapper-background'
import styled from "styled-components"
import theme from "../theme"
import ContentWrapper from "../components/wrapper-content"

const FooterContent = styled.div`
  font-size: ${theme.fontSize.font14}; 
`

const Navigation = styled.nav`
  margin-bottom: 20px;

  li {
    display: block;
    margin-bottom: 0;
    text-transform: uppercase
  }

  a:link, a:visited {
    text-decoration: none;
  }

  a:hover, a:active, a:focus {
    text-decoration: underline;
  }
`

const Footer = () => (

  <footer>
    <BackgroundWrapper className="backgroundBrown">

      <FooterContent>

        <Navigation>
          <ul>
            <li><Link to="buy-chiva-beans">Buy our beans</Link></li>
            <li><Link to="about-chiva">About Chiva</Link></li>
            <li><Link to="contact">Get in touch</Link></li>
            <li><Link to="privacy">Privacy</Link></li>
            <li><Link to="terms">Terms</Link></li>
          </ul>
        </Navigation>

        <ImageChivaLogoSmall />

        <ContentWrapper>

          <p>Shipping is included in the price you pay for your beans, please allow up to 7 working days for delivery</p>

        </ContentWrapper>

        <p>&copy; Chiva Coffee Ltd {new Date().getFullYear()}<br />Site by <a rel="noopener noreferrer" target="_blank" href="https://backtofront.co">Back to Front</a></p>

      </FooterContent>

    </BackgroundWrapper>
  </footer>

)

export default Footer
const color = {
  white: "#fff",
  black: "#2e2f33",
  gray: "#bcbcbc",
  hover: "#000",
}

const font = {
  header: `'AnzeigenGro', sans-serif`,
}

const columnGap = {
  regular: "2rem",
  small: "1rem",
}

const mediaQuery = {
  small: "768px",
  large: "1070px",
}

const fontSize = {
  font28: "1.75rem", // 28px
  font24: "1.5rem", // 24px
  font17: "1.063rem", // 17px
  font16: "1rem", // 16px
  font14: "0.875rem", // 14px
}

const boxShadow = {
  regular: "10px 10px 0px #a9a79d",
}

const transition = {
  all: "all .3s",
}

export default {
  color,
  font,
  fontSize,
  boxShadow,
  columnGap,
  mediaQuery,
  transition
}
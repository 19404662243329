import React from "react"
import { Link } from "gatsby"
import ImageChivaLogoHoriz from "../components/img-chiva-logo-horiz"
import styled from "styled-components"
import MainNav from "../components/nav"
import BgImg from '../images/background_texture_brown.jpg'; 

const Wrapper = styled.div`
  background-image: url(${BgImg});
  position: sticky;
  top: 0;
  z-index: 50;
`

const Content = styled.header`
  display: flex;
  margin: auto;
  max-width: 1070px;
  justify-content: space-between;
  align-items: center;  
  padding: 20px 20px 5px;
`

const Shadow = styled.div`
  height: 10px;
  box-shadow: 0 6px 9px -2px rgba(0,0,0,0.37);
`

const Header = () => (

  <Wrapper>
    <Content>
      
      <div>
        <Link to="/">
          <ImageChivaLogoHoriz />
        </Link>
      </div>

      <MainNav />

    </Content>

    <Shadow />
  </Wrapper>

)

export default Header

import styled from 'styled-components';
import theme from "../../theme"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #2c8ca7;
  height: 100vh;
  text-align: center;
  padding: 10rem 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  width: 100%;
  z-index: 50;

  a:link, a:visited {
    color: #fff;
  }

  a {
    font-family: ${theme.font.header};
    font-size: 1.444rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s linear;
    letter-spacing: 2px;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }
`;
import React, { useState } from 'react';
import { Link } from "gatsby"
import './wrapper-background.css'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import Burger from './burger';
import Menu from './menu';
import theme from "../theme"

const Navigation = styled.nav`
  margin-left: 0;
  display: block;
  
  @media (max-width: ${theme.mediaQuery.small}) {
    display: none;
  }  

  ul {
    display: inline-block;
  }

  ul:last-child {
    margin-left: 20px;
  }

  li {
    font-size: 0.889rem;
    display: inline-block;
    text-transform: uppercase;
    margin-left: 10px;
    margin-bottom: 0;
  }

  a:link, a:visited {
    text-decoration: none;
  }

  a:hover, a:active, a:focus {
    text-decoration: underline;
  }
`

const MobileNavigation = styled.div `
  display: none;
  
  @media (max-width: ${theme.mediaQuery.small}) {
    display: block;
  }
`
 
function MainNav() {
  const [open, setOpen] = useState(false);
  return (

    <>

      <MobileNavigation>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </MobileNavigation>

      <Navigation>
        <ul>
          <li><Link to="buy-chiva-beans">Buy our beans</Link> | </li>
          <li><Link to="about-chiva">About Chiva</Link> | </li>
          <li><Link to="contact">Get in touch</Link></li>
        </ul>
        <ul>
          <li><a href="https://twitter.com/drinkchiva"><FontAwesomeIcon icon={faTwitter} /></a></li> 
          <li><a href="https://www.instagram.com/drinkchiva/"><FontAwesomeIcon icon={faInstagram} /></a></li> 
        </ul>
      </Navigation>

    </>

    );
  }
  export default MainNav;
import React from 'react'
import './wrapper-background.css'
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 60px 0;
  margin-bottom: 5px;
  text-align: center;
`

const BackgroundWrapper = ( props ) => {
  
  return (

    <Wrapper className={props.className}>
      { props.children }
    </Wrapper>
  )
}

export default BackgroundWrapper